import { Button, Flash, FormControl, StyledOcticon, TextInput } from '@primer/react';
import React, { useState } from 'react';
import api from './service/api';
import { useForm } from 'react-hook-form';
import { CheckIcon } from '@primer/octicons-react';

const emailMatcher = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface InviteFormData {
  email: string
}

function InviteForm(props: { deviceId: string, triggerEmailSent: Function}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<InviteFormData>()
  const [emailSent, setEmailSent] = useState(false);

  async function sendInvite(form: InviteFormData) {
    await api.post<'devices/:id/shares'>('devices/' + props.deviceId + '/shares', { email: form.email });
    setEmailSent(true);
    setValue('email','');
    props.triggerEmailSent();
  }

  return (
    <form onSubmit={handleSubmit(sendInvite)}>
      {emailSent &&
        <Flash variant="success">
          <StyledOcticon icon={CheckIcon} />
          Email saadeti ära. Inimene tekib siia nimekirja siis kui ta on kirjas leiduva lingi kaudu siia süsteemi sisse loginud.
        </Flash>
      }
      <FormControl>
        <FormControl.Label>E-mail kuhu kutse saadetakse *</FormControl.Label>
        <TextInput block {...register('email', {
          required: { value: true, message: "Email on kohustuslik kutse saatmiseks!"},
           pattern: { value: emailMatcher, message: "Ei ole emaili moodi!"}
           })} />
        { errors.email &&
          <FormControl.Validation variant="error">
            { errors.email.message }
          </FormControl.Validation>
        }
      </FormControl>

      <Button variant='primary' type="submit" style={{ marginTop: 8 }} className={'btn-block'}>
        Saada kutse
      </Button>
    </form>
  );
}

export default InviteForm;
