import { useForm } from 'react-hook-form';
import { nonInterceptedApi } from './service/api';
import { Box, Button, Flash, FormControl, TextInput } from '@primer/react';
import { FormFooter35 } from './components/FormFooter';
import React, { useState } from 'react';
import { ContainerMd } from './components/layout/container';
import { SubheadHeading } from './components/subhead';
import { useParams } from 'react-router-dom';

function CreateAccountFinish() {
  const { token } = useParams();
  const [tokenError, setTokenError] = useState(false);
  const [passwordSet, setPasswordSet] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ password1: string; password2: string }>();

  async function onSubmit(form: { password1: string; password2: string}) {
    if (form.password1 !== form.password2) {
      setError('password2',{type: 'value', message: 'Paroolid ei klapi'});
      return;
    }

    try {
      await nonInterceptedApi.post('auth/user/savePassword', { password: form.password1, token });
      setPasswordSet(true);
    } catch (error) {
      // @ts-ignore
      if (error.response?.status == 403) {
        setTokenError(true);
      } else {
        throw error;
      }
    }
  }

  return (
    <Box as='main' sx={{ bg: 'canvas.default', flexFlow: 'column', minHeight: '100vh' }}>
      <ContainerMd sx={{ px: [16, 24, 32], my: 6 }}>
        <SubheadHeading>Sisesta uus salasõna</SubheadHeading>
        { tokenError && <Flash variant="danger">
          Selle konto loomise emailiga on juba konto loodud või aegus emailis olev URL. <a href="/createAccount">Saada email uuesti</a>.
        </Flash>}
        { passwordSet && <Flash variant="success">
          Salasõna salvestatud. <a href="/login">Logi sisse</a>.
        </Flash>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display='grid' gridGap={3} pt={3}>
            <FormControl>
              <FormControl.Label>Salasõna</FormControl.Label>
              <TextInput block type='password' {...register('password1', { required: { value: true, message: 'See on kohustuslik!' } })} />
              {errors.password1 && <FormControl.Validation variant='error'>{ errors.password1.message }</FormControl.Validation>}
            </FormControl>
            <FormControl>
              <FormControl.Label>Korda salasõna</FormControl.Label>
              <TextInput block  type='password' {...register('password2', { required: { value: true, message: 'See on kohustuslik!' } })} />
              {errors.password2 && <FormControl.Validation variant='error'>{ errors.password2.message }</FormControl.Validation>}
            </FormControl>
            <FormFooter35>
              <Button variant='primary' type='submit' disabled={passwordSet || tokenError}>
                Sisesta
              </Button>
            </FormFooter35>
          </Box>
        </form>
      </ContainerMd>
    </Box>
  );
}

export default CreateAccountFinish;
