import Header from './Header';
import React, { useEffect, useState } from 'react';
import api from './service/api';
import { Device, DeviceStored } from './service/ProtecthumAPI';
import moment from 'moment';
import { ReactComponent as SignalNo } from './images/SignalNo.svg';
import { ReactComponent as SignalWeak } from './images/SignalWeak.svg';
import { ReactComponent as SignalFair } from './images/SignalFair.svg';
import { ReactComponent as SignalGood } from './images/SignalGood.svg';
import { ReactComponent as SignalExcellent } from './images/SignalExcellent.svg';
import { Box, Button, StyledOcticon, Text, Tooltip } from '@primer/react';
import { ReactComponent as Battery0 } from './images/patarei0.svg';
import { ReactComponent as Battery25 } from './images/patarei25.svg';
import { ReactComponent as Battery50 } from './images/patarei50.svg';
import { ReactComponent as Battery75 } from './images/patarei75.svg';
import { ReactComponent as Battery100 } from './images/patarei100.svg';
import { ReactComponent as AlarmOffline } from './images/alarm_signal_offline.svg';
import { ReactComponent as AlarmFireOn } from './images/alarm_fire_active.svg';
import { ReactComponent as AlarmFireOff } from './images/alarm_fire_inactive.svg';
import { ReactComponent as AlarmSmokeOn } from './images/alarm_smoke_active.svg';
import { ReactComponent as AlarmSmokeOff } from './images/alarm_smoke_inactive.svg';
import { ReactComponent as AlarmMovementOn } from './images/alarm_movement_active.svg';
import { ReactComponent as AlarmMovementOff } from './images/alarm_movement_inactive.svg';
import { ReactComponent as AlarmObjectOn } from './images/alarm_object_active.svg';
import { ReactComponent as AlarmObjectOff } from './images/alarm_object_inactive.svg';
import { ReactComponent as AlarmFireNoack } from './images/alarm_fire_noack.svg';
import { ReactComponent as AlarmSmokeNoack } from './images/alarm_smoke_noack.svg';
import { ReactComponent as AlarmMovementNoack } from './images/alarm_movement_noack.svg';
import { ReactComponent as AlarmObjectNoack } from './images/alarm_object_noack.svg';
import { EyeIcon, LightBulbIcon } from '@primer/octicons-react';
import { ContainerXl } from './components/layout/container';
import { SubheadHeading } from './components/subhead';
import { Link } from 'react-router-dom';

function getBatteryIcon(percent: number | null) {
  if (percent === null)
    return (
      <Tooltip aria-label={'Andmed puuduvad'}>
        <Battery0 />
      </Tooltip>
    );

  if (percent < 10)
    return (
      <Tooltip aria-label={percent + '%'}>
        <Battery0 />
      </Tooltip>
    );

  if (percent < 30)
    return (
      <Tooltip aria-label={percent + '%'}>
        <Battery25 />
      </Tooltip>
    );

  if (percent < 60)
    return (
      <Tooltip aria-label={percent + '%'}>
        <Battery50 />
      </Tooltip>
    );

  if (percent < 80)
    return (
      <Tooltip aria-label={percent + '%'}>
        <Battery75 />
      </Tooltip>
    );

  return (
    <Tooltip aria-label={percent + '%'}>
      <Battery100 />
    </Tooltip>
  );
}

function signalStrength(lte_rssi: number, lte_rsrq: number) {
  if (lte_rssi === null || lte_rssi < -110) {
    return (
      <Tooltip aria-label={`Signaal puudub (RSRP ${lte_rssi} dBm, RSRQ ${lte_rsrq})`}>
        <SignalNo />
      </Tooltip>
    );
  }

  if (lte_rssi < -100) {
    return (
      <Tooltip aria-label={`Signaal nõrk (RSRP ${lte_rssi} dBm, RSRQ ${lte_rsrq})`}>
        <SignalWeak />
      </Tooltip>
    );
  }

  if (lte_rssi < -90) {
    return (
      <Tooltip aria-label={`Signaal piisav (RSRP ${lte_rssi} dBm, RSRQ ${lte_rsrq})`}>
        <SignalFair />
      </Tooltip>
    );
  }

  if (lte_rssi < -80) {
    return (
      <Tooltip aria-label={`Signaal hea (RSRP ${lte_rssi} dBm, RSRQ ${lte_rsrq})`}>
        <SignalGood />
      </Tooltip>
    );
  }

  if (lte_rssi >= -80) {
    return (
      <Tooltip aria-label={`Signaal suurepärane (RSRP ${lte_rssi} dBm, RSRQ ${lte_rsrq})`}>
        <SignalExcellent />
      </Tooltip>
    );
  }

  return (
    <Tooltip aria-label={`Signaali tugevus teadmata (RSRP ${lte_rssi} dBm, RSRQ ${lte_rsrq})`}>
      <SignalNo />
    </Tooltip>
  );
}

function SmokeAlarmIcon(props: { variant: 'on'| 'off'| 'noack', smokeLevel: number}) {
  switch (props.variant) {
    case 'on':
      return <Tooltip aria-label={`Tase ${props.smokeLevel}`}><AlarmSmokeOn key="smoke"/></Tooltip>
    case 'off':
      return <AlarmSmokeOff  key="smoke"/>
    case 'noack':
      return <AlarmSmokeNoack  key="smoke"/>
  }
}

export function AlarmPanel(alarm_status: number | null, settings: {[key: string]: boolean}, unseen: Device['unseen'], smokeLevel: number) {
  if (alarm_status === null) {
    alarm_status = 0;
  }

  const bitArray = Array.from(alarm_status.toString(2)).reverse();
  const showArray = [settings.fire, settings.smoke, settings.movement, settings.object];
  const noAckArray = [unseen.fire, unseen.smoke, unseen.movement, unseen.object];
  const bitmap = [<AlarmFireOn key="fire"/>, <SmokeAlarmIcon variant='on' smokeLevel={smokeLevel}/>, <AlarmMovementOn key="move"/>, <AlarmObjectOn key="object"/>];
  const bitmapGrey = [<AlarmFireOff key="fire"/>, <AlarmSmokeOff  key="smoke"/>, <AlarmMovementOff key="move"/>, <AlarmObjectOff key="object"/>];
  const bitmapNoack = [<AlarmFireNoack key="fire"/>, <AlarmSmokeNoack  key="smoke"/>, <AlarmMovementNoack key="move"/>, <AlarmObjectNoack key="object"/>];

  const alarmPanel = bitmap.map((e, i) => {
      // @ts-ignore
    return bitArray[i] === '1' ? bitmap[i] : (noAckArray[i] !== null && noAckArray[i] > 0)? bitmapNoack[i] : bitmapGrey[i];
  });

  const showPanel = alarmPanel.map((e, i) => {
    return showArray[i] === true ? e : <></>
  });

  return showPanel;
}

export function getLocalTime(time: string | null) {
  if (time === null) {
    return '';
  }
  return moment(new Date(time)).format('DD.MM.YYYY HH:mm:ss');
}

function getAlarmPanel(device: Device) {
  return (device.offline === undefined || device.offline === true)?
  <Box>
    <Tooltip aria-label='Seadmega puudub ühendus'><AlarmOffline/></Tooltip>
    { getBatteryIcon(null) }
    <Link to={`/devices/${device.id}/alarms`}>
      { AlarmPanel(0, device.settings, device.unseen, device.smoke_level) }
    </Link>
  </Box>
  :
  <Box>
    { signalStrength(device.lte_rssi, device.lte_rsrq) }
    { getBatteryIcon(device.voltage) }
    <Link to={`/devices/${device.id}/alarms`}>
      { AlarmPanel(device.alarm_status, device.settings, device.unseen, device.smoke_level) }
    </Link>
  </Box>
}

function Devices() {
  const [devices, setDevices] = useState([] as DeviceStored[]);

  function canIEdit(device: DeviceStored) {
    return device.role === 'owner';
  }

  useEffect(() => {
    function getDevices() {
      api.get('devices').then((resp) =>
        setDevices(
          resp.data.items.map((item) => {
            return {
              ...item,
              publicId2: canIEdit(item)? <a href={'/devices/' + item.id}>{item.publicId}</a> : item.publicId,
              chargePercentage: getBatteryIcon(item.voltage),
              wifiStrength: signalStrength(item.lte_rssi, item.lte_rsrq),
              latest: <p style={{ whiteSpace: 'nowrap' }}>{getLocalTime(item.greatest_msg_time)}</p>,
            };
          }),
        ),
      );
    }
    getDevices();
    const timer = setInterval(getDevices, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box bg="canvas.default">
      <Header />
      <Box as="main">
        <ContainerXl sx={{ pt: 4, px: 2}}>
          <Box display="flex" sx={{pb: 4}}>
            <SubheadHeading>Seadmed</SubheadHeading>
            <Link to="/devices/add">
              <Button>Registreeri seade</Button>
            </Link>
          </Box>
          <Box as='table' sx={{ width: '100%',
            mb: '4',
            borderCollapse: 'separate',
            borderSpacing: 0,
            '> thead tr td': {borderTop: '1px solid'},
            '> thead tr td, > tbody, tr, td': { borderBottom: '1px solid', padding: '5px', borderColor: 'border.default'},
            '> thead tr td:first-child, > tbody tr td:first-child': {borderLeft: '1px solid', borderColor: 'border.default'},
            '> thead tr td:last-child, > tbody tr td:last-child': {borderRight: '1px solid', borderColor: 'border.default'},
            '> thead tr first:child, > tbody tr first:child': {borderLeft: '1px solid', borderColor: 'border.default'},
            '> thead tr:first-child td:first-child': {borderTopLeftRadius: '6px'},
            '> thead tr:first-child td:last-child': {borderTopRightRadius: '6px'},
            '> tbody tr:last-child td:first-child': {borderBottomLeftRadius: '6px'},
            '> tbody tr:last-child td:last-child': {borderBottomRightRadius: '6px'}

          }}>
            <Box as='thead' sx={{backgroundColor: 'canvas.subtle'}}>
              <Box as='tr'>
                <td><Text fontWeight={'bold'}>Nimi</Text></td>
                <Box as='td' display={['none', 'table-cell']}><Text fontWeight={'bold'}>Aadress</Text></Box>
                <Box as='td' display={['none', 'table-cell']} sx={{p: 3}}><Text fontWeight={'bold'}>ID</Text></Box>
                <td><Text fontWeight={'bold'}>Alarmid</Text></td>
                <Box as='td' display={['none', 'table-cell']}><Text fontWeight={'bold'}>Viimane sõnum</Text></Box>
                <td></td>
              </Box>
            </Box>
            <tbody>
            {devices.map((item, index) => {
              return (
                <Box as='tr' key={item.id} className="Box-row">
                  <td><StyledOcticon icon={item.kind === 'Sensor' ? EyeIcon : LightBulbIcon } sx={{mr: 2}}/>
                  <strong><a href={'/devices/' + item.id}>{ item['room'] }</a></strong></td>
                  <Box as='td' display={['none', 'table-cell']}>{ item['address'] }</Box>
                  <Box as='td' display={['none', 'table-cell']} sx={{p:2}} >{item.publicId}</Box>
                  <td>{ getAlarmPanel(item) }</td>
                  <Box as='td' display={['none', 'table-cell']}><p style={{ whiteSpace: 'nowrap' }}>{getLocalTime(item.greatest_msg_time)}</p></Box>
                  <td></td>
                </Box>
              );
            })}
            </tbody>
          </Box>
        </ContainerXl>
      </Box>
    </Box>
  );
}

export default Devices;
