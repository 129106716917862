import { Box, Button, FormControl, Link, TextInput } from '@primer/react';
import { FormFooter } from './components/FormFooter';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import api from './service/api';
import { Device, DeviceStored } from './service/ProtecthumAPI';

function DevicesForm(props: { preloadedValues: DeviceStored | undefined }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Device>({ defaultValues: props.preloadedValues });
  const navigate = useNavigate();

  async function onSubmit(form: any) {
    if (props.preloadedValues) {
      await api.post('devices/' + props.preloadedValues.id, form);
      navigate('/devices/' + props.preloadedValues.id);
    } else {
      const res = await api.post('devices', form, { validateStatus: (status) => {
        return (status >= 200 && status < 300 ) || status === 404}
         });
      if (res.status === 201) {
        navigate('/devices');
      }

      if (res.status === 404) {
       setError('publicId', {type: 'server', message: 'Ei leidnud sellist vaba seadet!'});
      }
    }
  }

  async function deleteDevice() {
    // @ts-ignore
    await api.delete(`devices/${props.preloadedValues.id}` );
    navigate('/devices');
  }

  function showDisabled() {
    if (props.preloadedValues) {
      return {'disabled': true}
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="grid" gridGap={3}>
        <FormControl>
          <FormControl.Label>
            Seadme IMEI kood *
          </FormControl.Label>
          <TextInput block {...showDisabled()} {...register('publicId', { required: { value: true, message: 'Seadme IMEI peab olema määratud' } })} />
          {errors.publicId && <FormControl.Validation variant='error'>{ errors.publicId.message }</FormControl.Validation>}
        </FormControl>
        <FormControl>
          <FormControl.Label htmlFor="room">Nimi (ruumi nimi kus asub)</FormControl.Label>
          <TextInput block {...register('room')} />
        </FormControl>
        <FormControl>
          <FormControl.Label htmlFor="address">Aadress lühidalt</FormControl.Label>
          <TextInput block {...register('address')} />
        </FormControl>
        <FormFooter>
          {!props.preloadedValues && (
          <Button as={Link} onClick={() => navigate('/devices')} sx={{ margin: 1 }}>
            Tagasi
          </Button>)}
          <Button variant='primary' type="submit" sx={{ margin: 1 }}>
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
          {props.preloadedValues && (
            <Button variant='danger' type="button" sx={{ margin: 1 }} onClick={() => deleteDevice()}>
              Kustuta
            </Button>
          )}
        </FormFooter>
      </Box>
    </form>
  );
}

export default DevicesForm;
