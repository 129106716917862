import { useParams } from 'react-router-dom';
import { useDevice } from './Notifications';
import { Box } from '@primer/react';
import Header from './Header';
import DeviceHeader from './DeviceHeader';
import { ContainerMd } from './components/layout/container';
import { SubheadHeading } from './components/subhead';
import React, { useEffect, useState } from 'react';
import { NotificationForm } from './NotificationAdd';
import { Notification } from './service/ProtecthumAPI';
import api from './service/api';

function useNotificationData(deviceId?: string, notificationId?: string) {
  const [notification, setNotification] = useState(undefined as Notification | undefined);

  function getNotification() {
    api.get<'actions/:id/action/:notificationId'>(`actions/${deviceId}/action/${notificationId}`).then( res => {
      setNotification(res.data);
    })
  }

  function editNotification(ac: Notification) {
    api.put<'actions/:id/action/:notificationId'>(`actions/${deviceId}/action/${notificationId}`, ac).then( res => {
      getNotification();
    })
  }

  useEffect(() => {
    if (deviceId && notificationId) {
      getNotification();
    }
  }, [deviceId, notificationId]);

  return { notification, setNotification, editNotification, getNotification }
}

function NotificationEdit() {
  const { id, notificationId } = useParams();
  const { device } = useDevice(id as string);
  const { notification } = useNotificationData(device?.id, notificationId);

  return <Box bg="canvas.default">
    <Header />
    <Box as="main">
      {device && <DeviceHeader device={device} selected="notifications"/>}
      <ContainerMd sx={{ pt: 4}}>
        <SubheadHeading>Teavituse konfiguratsioon</SubheadHeading>
        {notification && id && <NotificationForm deviceId={id} preloadedValue={notification}/>}
      </ContainerMd>
    </Box>
  </Box>
}

export default NotificationEdit;