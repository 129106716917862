import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import React, { useState } from 'react';
import { ContainerMd } from './components/layout/container';
import { Box, Button, Checkbox, CheckboxGroup, FormControl, Select, TextInput } from '@primer/react';
import { SubheadHeading } from './components/subhead';
import { useForm, Controller } from 'react-hook-form';
import { FormFooter35 } from './components/FormFooter';
import { nonInterceptedApi as api } from './service/api';
import { useDevice } from './Notifications';
import DeviceHeader from './DeviceHeader';
import { TimePicker } from 'antd';
import { Notification } from './service/ProtecthumAPI';
import dayjs from 'dayjs';

function NotificationAdd() {
  const { id } = useParams();
  const { device } = useDevice(id as string);

  return <Box bg="canvas.default">
    <Header />
    <Box as="main">
      {device && <DeviceHeader device={device} selected="notifications"/>}
      <ContainerMd sx={{ pt: 4}}>
        <SubheadHeading>Teavituse konfiguratsiooni lisamine</SubheadHeading>
        <NotificationForm deviceId={id as string}/>
      </ContainerMd>
    </Box>
  </Box>
}

interface ConfForm {
  alarm: number
  action: string
  target: string
  startTime?: any //Dayjs
  endTime?: any //Dayjs
  weekdays: {
    mon: boolean
    tue: boolean
    wed: boolean
    thu: boolean
    fri: boolean
    sat: boolean
    sun: boolean
  }
}

function toFormData(notification: Notification): ConfForm {
  return {
    ...notification,
    startTime: notification.startTime ? dayjs(notification.startTime, "HH:mm:ss") : undefined,
    endTime: notification.endTime ? dayjs(notification.endTime,"HH:mm:ss") : undefined,
  };
}
function toNotification(formData: ConfForm): Notification {
  return {
    ...formData,
    startTime: formData.startTime ? formData.startTime!.format('HH:mm') : undefined,
    endTime: formData.endTime ? formData.endTime!.format('HH:mm') : undefined,
  } as Notification;
}

export function NotificationForm(props: {deviceId: string, preloadedValue?: Notification }) {
  const [phone, setPhone] = useState(props.preloadedValue ? (props.preloadedValue.action === 'call' || props.preloadedValue.action === 'sms') : false);
  const [targetLabel, setTargetLabel] = useState(props.preloadedValue && (props.preloadedValue.action === 'call' || props.preloadedValue.action === 'sms') ? 'Telefonile' : 'E-posti aadressile');
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setError,
  } = useForm<ConfForm>({ defaultValues: props.preloadedValue ? toFormData(props.preloadedValue!): undefined });

  async function onSubmit(form: ConfForm) {
    if (!validate(form)) {
      return;
    }

    if (form.action === 'sms' || form.action === 'call') {
      form.target = form.target.replaceAll(' ','');
    }

    const notification = toNotification(form);
    notification.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (props.preloadedValue?.id) {
      notification.id = props.preloadedValue.id;
      await api.put<'actions/:id/action/:notificationId'>(`actions/${props.deviceId}/action/${props.preloadedValue.id}`, notification);
    } else {
      await api.post<'actions/:id'>(`actions/${props.deviceId}`, notification);
    }

    navigate(`/devices/${props.deviceId}/notifications`);
  }

  function validate(form: ConfForm | Notification) {
    if (form.action === 'sms' || form.action === 'phone') {
      if (!/^\+[0-9]+$/.test(form.target.replaceAll(' ',''))) {
        setError('target', {type: 'value', message: 'Telefoninumber peab sisaldama ainult numbreid ja algama plussi ja riigikoodiga'})
        return false;
      }
    } else {

    }
    return true;
  }

  function fieldType() {
    return (phone)? {type: 'tel'} : {type: 'email'};
  }

  function triggerChange() {
    const values = getValues();
    if ((values.action === 'sms' || values.action === 'call') && !phone ) {
      setPhone(true);
      setTargetLabel('Telefonile');
    }

    if (phone && !(values.action === 'sms' || values.action === 'call')) {
      setPhone(false);
      setTargetLabel('E-posti aadressile');
    }
  }

  return (<form onSubmit={handleSubmit(onSubmit)}>
    <Box display="grid" gridGap={3} sx={{pt: 4}}>
      <FormControl>
        <FormControl.Label>
          Kui seade annab alarmi ...
        </FormControl.Label>
        <Select block {...register('alarm', { required: { value: true, message: 'Vali Alarmi tüüp' } })}>
          <Select.Option value="1">Tuli</Select.Option>
          <Select.Option value="2">Suits</Select.Option>
          <Select.Option value="4">Liikumine</Select.Option>
          <Select.Option value="8">Objekt</Select.Option>
          <Select.Option value="16">Patarei tühjeneb</Select.Option>
          <Select.Option value="32">Seade pole võrgus</Select.Option>
        </Select>
      </FormControl>
      <FormControl>
        <FormControl.Label>... siis ...</FormControl.Label>
        <Select block {...register('action', { onChange: (e) => { triggerChange() },required: { value: true, message: 'Vali kanal' } })}>
          <Select.Option value="email">Saada Email</Select.Option>
          <Select.Option value="sms">Saada SMS</Select.Option>
          <Select.Option value="call">Helista</Select.Option>
        </Select>
      </FormControl>
      <FormControl>
        <FormControl.Label>{targetLabel}</FormControl.Label>
        <TextInput block {...fieldType()} {...register('target', { required: { value: true, message: 'Väli on kohustuslik' } })} />
        {errors.target && <FormControl.Validation variant='error'>{ errors.target.message }</FormControl.Validation>}
      </FormControl>
      <FormControl>
        <FormControl.Label>Algusaeg</FormControl.Label>
        <Controller
          name="startTime"
          control={control}
          render={({ field }) => <TimePicker
            {...field}
            format='HH:mm'
            placeholder="Vali aeg"
            showNow={false}
            minuteStep={5}
            className='darkTime'
            popupClassName='popupTime'
          />}
        />
        {errors.startTime && <FormControl.Validation variant='error'>{ errors.startTime.message }</FormControl.Validation>}
      </FormControl>
      <FormControl>
        <FormControl.Label>Lõppaeg</FormControl.Label>
        <Controller
          name="endTime"
          control={control}
          render={({ field }) => <TimePicker {...field} format='HH:mm' placeholder="Vali aeg" showNow={false} minuteStep={5} />}
        />
        {errors.endTime && <FormControl.Validation variant='error'>{ errors.endTime.message }</FormControl.Validation>}
      </FormControl>
      <FormControl>
        <FormControl.Label>Nädalapäevad</FormControl.Label>
        <CheckboxGroup>
          <FormControl>
            <Checkbox {...register('weekdays.mon')} />
            <FormControl.Label>Esmaspäev</FormControl.Label>
          </FormControl>
          <FormControl>
            <Checkbox {...register('weekdays.tue')} />
            <FormControl.Label>Teisipäev</FormControl.Label>
          </FormControl>
          <FormControl>
            <Checkbox {...register('weekdays.wed')} />
            <FormControl.Label>Kolmapäev</FormControl.Label>
          </FormControl>
          <FormControl>
            <Checkbox {...register('weekdays.thu')} />
            <FormControl.Label>Neljapäev</FormControl.Label>
          </FormControl>
          <FormControl>
            <Checkbox {...register('weekdays.fri')} />
            <FormControl.Label>Reede</FormControl.Label>
          </FormControl>
          <FormControl>
            <Checkbox {...register('weekdays.sat')} />
            <FormControl.Label>Laupäev</FormControl.Label>
          </FormControl>
          <FormControl>
            <Checkbox {...register('weekdays.sun')} />
            <FormControl.Label>Pühapäev</FormControl.Label>
          </FormControl>
        </CheckboxGroup>
      </FormControl>
      <FormFooter35>
        <Button variant='primary' type='submit'>
          { props.preloadedValue ? 'Muuda' : 'Lisa' }
        </Button>
        <Button onClick={() => {navigate(`/devices/${props.deviceId}/notifications`)}}>
          Tagasi
        </Button>
      </FormFooter35>
    </Box>
  </form>);
}

export default NotificationAdd;
