import { tempToColor } from 'temp-color';
import { Tooltip } from '@primer/react';

function Heatmap(props: { data: number[][], px?: number }) {
  const flatten = props.data.flat();
  const min = Math.min.apply(Math, flatten);
  const max = Math.max.apply(Math, flatten);

  const size = props.px ?? 20;
  var key = 0;

  return (<table><tbody>
    {
      props.data.map(row => {
        return <tr key={key++}>
          {row.map( cell => {
            const {r,g,b} = tempToColor(cell, min, max);
            return <td key={key++}><Tooltip aria-label={cell + '℃'}><div style={{backgroundColor: `rgb(${r},${g},${b})`, height: size, width: size}}></div></Tooltip></td>
          })}
        </tr>;
      })
    }
  </tbody></table>)
}

export default Heatmap;
