import Header from './Header';
import DevicesForm from './DevicesForm';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DeviceStored } from './service/ProtecthumAPI';
import api from './service/api';
import { ContainerMd } from './components/layout/container';
import DeviceHeader from './DeviceHeader';
import { Box, Checkbox, FormControl } from '@primer/react';
import { SubheadHeading } from './components/subhead';
import { ReactComponent as AlarmFireOff } from './images/alarm_fire_inactive.svg';
import { ReactComponent as AlarmSmokeOff } from './images/alarm_smoke_inactive.svg';
import { ReactComponent as AlarmObjectOff } from './images/alarm_object_inactive.svg';
import { ReactComponent as AlarmMovementOff } from './images/alarm_movement_inactive.svg';

function Settings() {
  const { id } = useParams();
  const [device, setDevice] = useState<DeviceStored | undefined>();

  function getDevice() {
    api.get<'devices/:id'>('devices/' + id).then( res => {
      setDevice(res.data);
    })
  }

  useEffect(() => {
    if (id) {
      getDevice();
    }
  }, [id]);

  return (
    <>
      <Header />
      <main>
        {device && <DeviceHeader device={device} selected=""/>}
      </main>
      <ContainerMd sx={{pt: 4}}>
        { device &&
          <>
            <DevicesForm preloadedValues={device}/>
            <VisibleAlarmIcons device={device} updatedTrigger={() => {getDevice()}}/>
          </>
        }
      </ContainerMd>
    </>
  );
}

function VisibleAlarmIcons(props: {device: DeviceStored, updatedTrigger: Function}) {

  function updateSettings(field: string) {
    const settings: { [key: string]: boolean } = props.device.settings;
    settings[field] = !settings[field];
    api.put<'devices/:id/settings'>(`devices/${props.device.id}/settings`, settings).then(res => {
        props.updatedTrigger();
    });
  }

  return (
    <Box>
      <SubheadHeading>Alarmide näitamine</SubheadHeading>
      <FormControl>
        <FormControl.Label>Tuli</FormControl.Label>
        <FormControl.LeadingVisual>
          <AlarmFireOff />
        </FormControl.LeadingVisual>
        <Checkbox checked={props.device.settings?.fire} onChange={() => {updateSettings('fire')}}/>
      </FormControl>
      <FormControl>
        <FormControl.Label>Suits</FormControl.Label>
        <FormControl.LeadingVisual>
          <AlarmSmokeOff />
        </FormControl.LeadingVisual>
        <Checkbox checked={props.device.settings?.smoke} onChange={() => {updateSettings('smoke')}}/>
      </FormControl>
      <FormControl>
      <FormControl.Label>Liikumine</FormControl.Label>
      <FormControl.LeadingVisual>
        <AlarmMovementOff />
      </FormControl.LeadingVisual>
      <Checkbox checked={props.device.settings?.movement} onChange={() => {updateSettings('movement')}}/>
      <FormControl.Caption>Liikuv objekt</FormControl.Caption>
    </FormControl>
      <FormControl>
        <FormControl.Label>Objekt</FormControl.Label>
        <FormControl.LeadingVisual>
          <AlarmObjectOff />
        </FormControl.LeadingVisual>
        <Checkbox checked={props.device.settings?.object} onChange={() => {updateSettings('object')}}/>
        <FormControl.Caption>Seisev objekt</FormControl.Caption>
      </FormControl>
    </Box>
  )
}


export default Settings;
