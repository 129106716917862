import { Box, Label, StyledOcticon, UnderlineNav } from '@primer/react';
import {
  AlertIcon,
  EyeIcon,
  GearIcon,
  HistoryIcon,
  LightBulbIcon,
  PeopleIcon,
} from '@primer/octicons-react';
import React from 'react';
import { DeviceStored } from './service/ProtecthumAPI';

function DeviceHeader(props: {device: DeviceStored, selected?: string}) {
  function markSelected(link: string) {
    if (link === props.selected) {
      return {selected: true};
    }
  }

  return <Box sx={{paddingTop: 16}}>
    <Box sx={{marginBottom: 16, fontSize: 20, px: 32}}>
      <Box sx={{display: 'flex', alignItems: 'center', lineHeight: 1.5}}>
          <StyledOcticon icon={props.device.kind === 'Sensor' ? EyeIcon : LightBulbIcon } sx={{mr: 2}}/>
        <strong>{props.device.room}</strong> <span style={{marginLeft: 4}}><Label>ID: {props.device.publicId}</Label></span>
      </Box>
    </Box>
    <UnderlineNav aria-label="Main" sx={{ px: 32 }}>
      <UnderlineNav.Link href={`/devices/${props.device.id}`} {...markSelected('')}><StyledOcticon icon={GearIcon} sx={{marginRight: '8px'}}/> Üldine</UnderlineNav.Link>
      <UnderlineNav.Link href={`/devices/${props.device.id}/alarms`} {...markSelected('alarms')} ><StyledOcticon icon={HistoryIcon} sx={{marginRight: '8px'}}/>Alarmide ajalugu</UnderlineNav.Link>
      <UnderlineNav.Link href={`/devices/${props.device.id}/notifications`} {...markSelected('notifications')}><StyledOcticon icon={AlertIcon} sx={{marginRight: '8px'}}/>Teavitused</UnderlineNav.Link>
      <UnderlineNav.Link href={`/devices/${props.device.id}/sharing`}  {...markSelected('sharing')}><StyledOcticon icon={PeopleIcon} sx={{marginRight: '8px'}}/>Jagamine</UnderlineNav.Link>
    </UnderlineNav>
  </Box>
}

export default DeviceHeader;