import { useEffect, useState } from 'react';
import { setCurrentUser } from './service/user.service';
import { Navigate, Path, useSearchParams } from 'react-router-dom';
import api from './service/api';
import { getStoredUrl, hasStoredUrl, removeStoredUrl } from './Router';

function LoggedIn() {
  const [isUserLoadingDone, setUserLoadingDone] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("/" as string | Partial<Path>);
  const [searchParams] = useSearchParams();
  const redir = searchParams.get('redir');

  useEffect(() => {
    async function setUser() {
      setCurrentUser((await api.get('auth/user')).data);

      if (hasStoredUrl()) {
        setRedirectUrl(getStoredUrl());
        removeStoredUrl();
      }

      setUserLoadingDone(true);
    };

    setUser();
  },[]);

  if (isUserLoadingDone) {
    return <Navigate to={redir ? redir : redirectUrl} />;
  } else
    return (
      <></>
    );
}

export default LoggedIn;
