import { SubheadHeading } from './components/subhead';
import React, { useState } from 'react';
import { Box, Button, Dialog, FormControl, Link, Text, TextInput } from '@primer/react';
import { ContainerMd } from './components/layout/container';
import { useForm } from 'react-hook-form';
import { FormFooter35 } from './components/FormFooter';
import { useNavigate } from 'react-router-dom';
import api from './service/api';

function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();
  const navigate = useNavigate();
  const [dialogIsOpen, setOpenDialog] = useState(false);
  const returnFocusRef = React.useRef(null);

  async function onSubmit(form: { email: string; }) {
    await api.post('auth/user/resetPassword', form);
    setOpenDialog(true);
  }

  return <Box as='main' sx={{ bg: 'canvas.default', flexFlow: 'column', minHeight: '100vh' }}>
    <ContainerMd sx={{ px: [16, 24, 32], my: 6 }}>
      <SubheadHeading>Saada salasõna uuenduseks sõnum</SubheadHeading>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Box display='grid' gridGap={3} pt={3}>
        <FormControl>
          <FormControl.Label>Email</FormControl.Label>
          <TextInput block {...register('email', { required: { value: true, message: 'Lisa oma email või telefoninumber' } })} />
          {errors.email && <FormControl.Validation variant='error'>{ errors.email.message }</FormControl.Validation>}
        </FormControl>
        <FormFooter35>
          <Button variant='primary' type='submit'>
            Saada email
          </Button>
          <Button as={Link} onClick={() => navigate('/')}>
            Tagasi
          </Button>
        </FormFooter35>
      </Box>
      </form>
    </ContainerMd>
    <Dialog
      returnFocusRef={returnFocusRef}
      isOpen={dialogIsOpen}
      onDismiss={() => setOpenDialog(false)}
      aria-labelledby="header-id"
    >
      <Dialog.Header id="header-id">Email saadetud</Dialog.Header>
      <Box p={3}>
        <Text>Saatsime sulle emaili, jätka paroolivahetust emailis sisalduvast lingist. Praeguse veebilehitseja akna võid kinni panna.</Text>
        <Box display="flex" mt={3} justifyContent="flex-end">
          <Button variant="primary"  onClick={() => navigate('/')}>OK</Button>
        </Box>
      </Box>
    </Dialog>
  </Box>
}

export default ResetPassword;

