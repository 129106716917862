import { Box, Button, Dialog, FormControl, Link, Text, TextInput } from '@primer/react';
import { useForm } from 'react-hook-form';
import { FormFooter35 } from './components/FormFooter';
import React, { useState } from 'react';
import api from './service/api';
import { ContainerMd } from './components/layout/container';
import { SubheadHeading } from './components/subhead';
import { useNavigate } from 'react-router-dom';

function CreateAccount() {
  return (
    <Box as='main' sx={{ bg: 'canvas.default', flexFlow: 'column', minHeight: '100vh' }}>
      <ContainerMd sx={{ px: [16, 24, 32], my: 6 }}>
        <SubheadHeading>Registreeri uus kasutaja</SubheadHeading>
        <EmailFlow/>
      </ContainerMd>
    </Box>
  );
}

function EmailFlow() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; name: string;}>();
  const [dialogIsOpen, setOpenDialog] = useState(false);
  const returnFocusRef = React.useRef(null);
  const navigate = useNavigate();

  async function onSubmit(form: { email: string; name: string;}) {
    await api.post('auth/user/register', form);
    setOpenDialog(true);
  }

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
    <Box display='grid' gridGap={3} pt={3}>
      <FormControl>
        <FormControl.Label>Nimi</FormControl.Label>
        <TextInput block {...register('name', { required: { value: true, message: 'Lisa oma nimi' } })} />
        {errors.name && <FormControl.Validation variant='error'>{ errors.name.message }</FormControl.Validation>}
      </FormControl>
      <FormControl>
        <FormControl.Label>E-mail</FormControl.Label>
        <TextInput block {...register('email', { required: { value: true, message: 'Lisa email' } })} />
        {errors.email && <FormControl.Validation variant='error'>{ errors.email.message }</FormControl.Validation>}
      </FormControl>
      <FormFooter35>
        <Button variant='primary' type='submit'>
          Saada jätkamiseks email
        </Button>
        <Button as={Link} onClick={() => navigate('/')}>
          Tagasi
        </Button>
      </FormFooter35>
    </Box>
  </form>
  <Dialog
    returnFocusRef={returnFocusRef}
    isOpen={dialogIsOpen}
    onDismiss={() => setOpenDialog(false)}
    aria-labelledby="header-id"
  >
    <Dialog.Header id="header-id">Email saadetud</Dialog.Header>
    <Box p={3}>
      <Text>Saatsime sulle emaili, jätka protsessi emailis sisalduvast lingist. Praeguse veebilehitseja akna võid kinni panna.</Text>
      <Box display="flex" mt={3} justifyContent="flex-end">
        <Button variant="primary"  onClick={() => navigate('/')}>OK</Button>
      </Box>
    </Box>
  </Dialog>
  </>
}

export default CreateAccount;
