import Header from './Header';
import DeviceHeader from './DeviceHeader';
import React, { useEffect, useState } from 'react';
import { ContainerLg } from './components/layout/container';
import { useDevice } from './Notifications';
import { useParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, StyledOcticon } from '@primer/react';
import { XIcon } from '@primer/octicons-react';
import { Share, User } from './service/ProtecthumAPI';
import api from './service/api';
import { getCurrentUser } from './service/user.service';
import InviteForm from './InviteForm';

function Sharing() {
  const { id } = useParams();
  const { device } = useDevice(id as string);
  const [users, setUsers] = useState(undefined as User[] | undefined);
  const [shares, setShares] = useState(undefined as Share[] | undefined);

  async function getSharings() {
    api.get<'devices/:id/shares'>(`devices/${id}/shares`).then((resp) => setShares(resp.data.items));
  }

  async function getUsers() {
    api.get<'devices/:id/users'>(`devices/${id}/users`).then((resp) => setUsers(resp.data.items));
  }

  useEffect(() => {
    getUsers();
    getSharings();
  }, [id]);

  async function deleteUser(deviceId: string, userId: string) {
    await api.delete<'devices/:id/users/:userId'>('devices/' + deviceId + '/users/' + userId);
    api.get<'devices/:id/users'>(`devices/${deviceId}/users`).then((resp) => setUsers(resp.data.items));
  }

  async function deleteSharing(deviceId: string, shareId: string) {
    await api.delete<'devices/:id/shares/:shareId'>(`devices/${deviceId}/shares/${shareId}`);
    getSharings();
  }

  async function setUserRole(userId: string,role: string) {
    await api.put<'devices/:id/users/:userId'>(`devices/${id}/users/${userId}`, {role: role});
    getUsers();
  }

  function markBtn(userRole: string, role: 'user'|'owner') {
    if (userRole === role) {
      return {variant: 'primary', disabled: true}
    }
  }

  const me = getCurrentUser();


  return <>
    <Header />
    <main>
      {device && <DeviceHeader device={device} selected="sharing"/>}
    </main>
    <ContainerLg sx={{ px: [16, 24, 32], my: 6 }}>
      <Box as='ul' sx={{
        'li:first-child': { borderTopLeftRadius: '6px', borderTopRightRadius: '6px' },
        'li:last-child': { borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }
      }}>
        {users &&
          users.map((user) => {
            return (
              <Box as='li'
                key={'u' + user.id}
                sx={{ borderColor: 'border.default', borderWidth: '1px', borderStyle:'solid', p: 3, marginTop: '-1px', display: 'flex', alignItems: 'center', listStyleType: 'none'}}
              >
                <div className="flex-auto">
                    <span className="text-bold">
                      {user.name} {me !== null && me.id === user.id && <span>(Teie)</span>}
                    </span>
                </div>
                {me !== null && me.id !== user.id && (
                  <>
                    <ButtonGroup>
                      <Button size='small' {...markBtn(user.role, 'user')} onClick={() => {setUserRole(user.id, 'user')}}>Kasutaja</Button>
                      <Button size='small' {...markBtn(user.role, 'owner')} onClick={() => {setUserRole(user.id, 'owner')}}>Omanik</Button>
                    </ButtonGroup>
                    <button className="btn-octicon" onClick={() => deleteUser(id as string, user.id)}>
                      <StyledOcticon icon={XIcon} />
                    </button>
                  </>
                )}
              </Box>
            );
          })}
        {shares &&
          shares.map((share) => {
            return (
              <Box as='li'
                   sx={{ borderColor: 'border.default', borderWidth: '1px', borderStyle:'solid', p: 3, marginTop: '-1px', display: 'flex', alignItems: 'center', listStyleType: 'none'}}
                key={'s' + share.id}
              >
                <div className="flex-auto">
                  <span className="text-bold">Kutse saadetud</span>
                  <div className="text-small color-fg-subtle">{share.email}</div>
                </div>
                <button className="btn-octicon" onClick={() => deleteSharing(id as string, share.id)}>
                  <StyledOcticon icon={XIcon} />
                </button>
              </Box>
            );
          })}
      </Box>
      <Box p={3}>
        <InviteForm deviceId={id as string} triggerEmailSent={() => getSharings()} />
      </Box>
    </ContainerLg>
    </>
}

export default Sharing;