import Header from './Header';
import React, { useEffect, useState } from 'react';
import { Alarm } from './service/ProtecthumAPI';
import api from './service/api';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalTime } from './Devices';
import { Box, Button, Link, StyledOcticon, Text, Tooltip } from '@primer/react';
import { ReactComponent as AlarmFire } from './images/alarm_fire_active.svg';
import { ReactComponent as AlarmObject } from './images/alarm_object_active.svg';
import { ReactComponent as AlarmMovement } from './images/alarm_movement_active.svg';
import { ReactComponent as AlarmSmoke } from './images/alarm_smoke_active.svg';
import { ReactComponent as AlarmOffline } from './images/alarm_signal_offline.svg';
import { ReactComponent as AlarmBattery } from './images/alarm_battery_v3.svg';
import { CheckIcon } from '@primer/octicons-react';
import { ContainerLg } from './components/layout/container';
import { useDevice } from './Notifications';
import DeviceHeader from './DeviceHeader';
import { getCurrentUser } from './service/user.service';

const bit_fire = 0;
const bit_smoke = 1;
const bit_movement = 2;
const bit_object = 3;
const bit_battery = 4;


export function getAlarmComponent(alarm_status: number, posOfBit: number) {
  if (alarm_status === null || alarm_status === 0) {
    return <svg height={24} width={24} />;
  }

  const bitArray = Array.from(alarm_status.toString(2)).reverse();
  const bitmap = [<AlarmFire />, <AlarmSmoke />, <AlarmMovement />, <AlarmObject />, <AlarmBattery/>];

  if (bitArray[posOfBit] === '1') {
    return bitmap[posOfBit];
  }

  return <svg height={24} width={24} />;
}

function Alarms() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [alarms, setAlarms] = useState(undefined as Alarm[] | undefined);
  const [moreDisabled, setMoreDisabled] = useState(true);
  const { device, getDevice } = useDevice(id as string);

  useEffect(() => {
    api.get<'devices/:id/alarms'>('devices/' + id + '/alarms').then((resp) => {
      const newAlarms = resp.data.items.map((item) => {
        return { ...item };
      });
      setAlarms(newAlarms);
      setMoreDisabled(newAlarms.length < 20);
    });
  }, [id]);

  function loadMore() {
    if (!alarms) {
      return;
    }
    const lastID = alarms[alarms.length - 1].id;
    api.get<'devices/:id/alarms'>('devices/' + id + '/alarms', { params: { after: lastID } }).then((resp) => {
      const newPage = resp.data.items.map((item) => {
        return { ...item };
      });

      if (newPage.length > 0) {
        setAlarms(alarms.concat(newPage));
      } else {
        setMoreDisabled(true);
      }
    });
  }

  function getAlarmStatus(alarm: Alarm) {
    if ((alarm as any).offline) {
      return <Tooltip aria-label="Signaal puudub kauem kui 3 tundi"><AlarmOffline/></Tooltip>;
    }

    return alarm.bits === 0? <Tooltip aria-label="Alarmid puuduvad, ühendus korras"><StyledOcticon icon={CheckIcon} sx={{color: 'green'}}/></Tooltip>:'';
  }

  function hasUnseenAlarms() {
    if (alarms && alarms[0] && device && device.ackedAlarms === null) {
      return true;
    }

    return (alarms && alarms[0] && device && device.ackedAlarms !== null && new Date(alarms[0].startTime) > new Date(device.ackedAlarms))
  }

  function doIHaveMarkAllReadPrivileges() {
    return device!.role === 'owner' || getCurrentUser()?.isAdmin;
  }

  function markAllRead() {
    api.post<'devices/:id/alarms/markAllRead'>(`devices/${id}/alarms/markAllRead`).then(
      () => {
        getDevice();
      }
    )
  }

  function isUnseen(item: Alarm) {
    if (!device || device.ackedAlarms === null) {
      return true;
    }

    const itemDate = new Date(item.startTime);
    const unseenDate = new Date(device.ackedAlarms);
    return itemDate > unseenDate;
  }

  return (
    <>
      <Header />
      <main>
        {device && <DeviceHeader device={device} selected="alarms"/>}
      </main>
      <ContainerLg sx={{ px: [16, 24, 32], my: 6 }}>
        {alarms !== undefined && alarms.length === 0 && (
          <div className="blankslate">
            <h3 className="blankslate-heading">Andmed puuduvad</h3>
            <p>{id} seade ei ole saatnud veel alarme.</p>
            <div className="blankslate-action">
              <Button variant='primary' as={Link} onClick={() => navigate('/devices')}>
                Tagasi
              </Button>
            </div>
          </div>
        )}
        {alarms !== undefined && alarms.length > 0 && (
          <>
            <Button sx={{ mb: 2 }} onClick={() => markAllRead()} disabled={(!hasUnseenAlarms()) || (!doIHaveMarkAllReadPrivileges())}>Märgi kõik alarmid nähtuks</Button>

            <Box as='table' sx={{
              borderCollapse: 'separate',
              borderSpacing: 0,
              '> thead tr td': {borderTop: '1px solid'},
              '> thead tr td, > tbody, tr, td': { borderBottom: '1px solid', padding: '8px', borderColor: 'border.default'},
              '> thead tr td:first-child, > tbody tr td:first-child': {borderLeft: '1px solid', paddingLeft: '16px', borderColor: 'border.default'},
              '> thead tr td:last-child, > tbody tr td:last-child': {borderRight: '1px solid', paddingRight: '16px', borderColor: 'border.default'},
              '> thead tr first:child, > tbody tr first:child': {borderLeft: '1px solid', borderColor: 'border.default'},
              '> thead tr:first-child td:first-child': {borderTopLeftRadius: '6px'},
              '> thead tr:first-child td:last-child': {borderTopRightRadius: '6px'},
              '> tbody tr:last-child td:first-child': {borderBottomLeftRadius: '6px'},
              '> tbody tr:last-child td:last-child': {borderBottomRightRadius: '6px'}
            }}>
              <Box as='thead' sx={{backgroundColor: 'canvas.subtle'}}>
                <tr>
                  <td><Text fontWeight='bold'>Aeg</Text></td>
                  <td><Text fontWeight='bold'>OK?</Text></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}><Tooltip aria-label='Tuli'><AlarmFire/></Tooltip></Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}><Tooltip aria-label='Suits'><AlarmSmoke/></Tooltip></Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}><Tooltip aria-label='Liikumine'><AlarmMovement/></Tooltip></Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}><Tooltip aria-label='Objekt'><AlarmObject/></Tooltip></Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}><Tooltip aria-label='Aku tühjenemas. Alla 10%'><AlarmBattery/></Tooltip></Box></td>
                </tr>
              </Box>
              <tbody>
              {alarms.map((alarm, index) => {
                return (<Box as='tr' key={alarm.id} sx={{backgroundColor: isUnseen(alarm)? 'attention.subtle' : undefined}}>
                  <td>{alarm.bits === 0? <span style={{ whiteSpace: 'nowrap'}}>{ alarm.startTime? getLocalTime(alarm.startTime) : '' }</span> : <Link href={'/devices/' + id + '/alarms/' + alarm.id} style={{ whiteSpace: 'nowrap'}}>{getLocalTime(alarm.startTime)}</Link>}</td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}>{getAlarmStatus(alarm)}</Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}>{getAlarmComponent(alarm.bits, bit_fire)}</Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}>{getAlarmComponent(alarm.bits, bit_smoke)}</Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}>{getAlarmComponent(alarm.bits, bit_movement)}</Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}>{getAlarmComponent(alarm.bits, bit_object)}</Box></td>
                  <td><Box as='div' sx={{ m: -1, boxSizing: 'border-box', width: '24px', height: '24px' }}>{getAlarmComponent(alarm.bits, bit_battery)}</Box></td>
                </Box>)
              })}
              </tbody>
            </Box>
            <div className="text-center">
              <Button variant='invisible' size="small" sx={{ margin: 1 }} onClick={() => loadMore()} disabled={moreDisabled}>
                Lae rohkem
              </Button>
            </div>
          </>
        )}
      </ContainerLg>
    </>
  );
}

export default Alarms;
