import { Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from './service/api';

function ShareInbound() {
  const { hash } = useParams();

  const [done, setDone] = useState(false);

  useEffect(() => {
    api.post('shares', { hash: hash }).then(resp => setDone(true));
  }, [hash]);

  if (done) {
    return (
      <Navigate to="/" />
    );
  }

  return (<></>);
}

export default ShareInbound;