import React from 'react';
import Router from './Router';
import { BaseStyles, themeGet, ThemeProvider } from '@primer/react';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider colorMode="auto">
        <ConfigProvider
          /*theme={{
            algorithm: theme.defaultAlgorithm,
          }}*/
          /*theme={{
            token: {
              colorPrimary: '#238636',
              colorBgBase: '#0d1117',
              colorTextBase: '#c9d1d9',
            },
          }}*/
        >
        <MyGlobalAddon />
        <BaseStyles>
          <Router />
        </BaseStyles>
        </ConfigProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const MyGlobalAddon = createGlobalStyle`
html { background-color: ${themeGet('colors.canvas.default')}; }
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !default;
  font-size: 14px;
  line-height: 1.5;
  color: ${themeGet('colors.fg.default')};
  background-color: ${themeGet('colors.canvas.default')};
}
a {
  color: ${themeGet('colors.accent.fg')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
`;

export default App;
