import Header from './Header';
import { Box, Button, Checkbox, Dialog, Text } from '@primer/react';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ContainerLg } from './components/layout/container';
import api from './service/api';
import { Notification, DeviceStored, WeekdayBooleans } from './service/ProtecthumAPI';
import { getCurrentUser } from './service/user.service';
import DeviceHeader from './DeviceHeader';


function useNotificationsData(deviceId: string) {
  const [confs, setConfs] = useState(undefined as Notification[] | undefined);

  function getConfs() {
    api.get<'actions/:id'>(`actions/${deviceId}`).then( res => {
      setConfs(res.data.items);
    })
  }

  async function deleteConf(id: string) {
    await api.delete<'actions/:id'>(`actions/${id}`);
    getConfs();
  }

  async function testConf(id: string) {
    await api.post<'actions/test/:id'>(`actions/test/${id}`);
  }

  async function toggleEnabled(item: Notification) {
    item.enabled === true ?
      await api.put<'actions/:id/disable'>(`actions/${item.id}/disable`) :
      await api.put<'actions/:id/enable'>(`actions/${item.id}/enable`);
    getConfs();
  }

  useEffect(() => {
    getConfs();
  }, [deviceId]);

  return {confs, getConfs, deleteConf, testConf, toggleEnabled}
}

export function useDevice(deviceId: string) {
  const [device, setDevice] = useState<DeviceStored | undefined>();

  function getDevice() {
    api.get<'devices/:id'>(`devices/${deviceId}`).then( res => {
      setDevice(res.data);
    })
  }

  useEffect(() => {
    getDevice();
  }, [deviceId]);

  return { device, getDevice }
}

function alarmCodeToText(code: number) {
  const labels = {
    '1': 'Tuli',
    '2': 'Suits',
    '4': 'Liikumine',
    '8': 'Objekt',
    '16': 'Patarei tühjeneb',
    '32': 'Seade pole võrgus',
  }

  // @ts-ignore
  return labels[code];
}

function Notifications() {
  const { id } = useParams();
  const { confs, deleteConf, testConf, toggleEnabled } = useNotificationsData(id as string);
  const { device } = useDevice(id as string);
  const [dialogIsOpen, setDialogOpen] = useState(false);
  const me = getCurrentUser();

  function isMyConf(conf: Notification) {
    return me && me.id === conf.userId;
  }

  return <Box bg="canvas.default">
    <Header />
    <Box as="main">
      {device && <DeviceHeader device={device} selected="notifications"/>}
      <ContainerLg sx={{ pt: 4}}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3}}>
          <Link to={`/devices/${id}/notificationsAdd`}>
            <Button variant='primary' sx={{ ml: 'auto', mr: 0}}>Lisa teavitus</Button>
          </Link>
        </Box>
        {confs &&
          <Box as='table' sx={{ width: '100%',
            mb: '4',
            borderCollapse: 'separate',
            borderSpacing: 0,
            '> thead tr td': {borderTop: '1px solid'},
            '> thead tr td, > tbody, tr, td': { borderBottom: '1px solid', padding: '8px', borderColor: 'border.default'},
            '> thead tr td:first-child, > tbody tr td:first-child': {borderLeft: '1px solid', borderColor: 'border.default'},
            '> thead tr td:last-child, > tbody tr td:last-child': {borderRight: '1px solid', borderColor: 'border.default'},
            '> thead tr first:child, > tbody tr first:child': {borderLeft: '1px solid', borderColor: 'border.default'},
            '> thead tr:first-child td:first-child': {borderTopLeftRadius: '6px'},
            '> thead tr:first-child td:last-child': {borderTopRightRadius: '6px'},
            '> tbody tr:last-child td:first-child': {borderBottomLeftRadius: '6px'},
            '> tbody tr:last-child td:last-child': {borderBottomRightRadius: '6px'}
          }}>
            <Box as='thead' sx={{backgroundColor: 'canvas.subtle'}}>
              <tr>
                <td><Text fontWeight='bold'>Aktiivne</Text></td>
                <td><Text fontWeight='bold'>Alarm</Text></td>
                <td><Text fontWeight='bold'>Tegevus</Text></td>
                <td><Text fontWeight='bold'>Kuhu</Text></td>
                <td><Text fontWeight='bold'>Ajavahemik</Text></td>
                <td><Text fontWeight='bold'>Testi</Text></td>
                <td><Text fontWeight='bold'>Kustuta</Text></td>
              </tr>
            </Box>
            <tbody>
            {
              confs.map((item) => {
                return (<Box as='tr' key={item.id}>
                  <td><Checkbox checked={item.enabled} disabled={!isMyConf(item)} onChange={() => toggleEnabled(item)} /></td>
                  <td><Link to={`/devices/${id}/notifications/${item.id}`}>{alarmCodeToText(item.alarm)}</Link></td>
                  <td>{item.action}</td>
                  <td>{item.target}</td>
                  <td>{showDuration(item.startTime,item.endTime, item.tz)} {printWeekdaysFromIntBit(item.weekdays)}</td>
                  <td>{
                    isMyConf(item)? <Button size='small' variant="invisible" onClick={() => {testConf(item.id); setDialogOpen(true);} }>
                      Testi
                    </Button> : <></>
                  }</td>
                  <td>{
                    isMyConf(item)? <Button size='small' variant="invisible" onClick={() => deleteConf(item.id)}>
                      Kustuta
                    </Button> : <></>
                  }</td>
                </Box>);
            })}
            </tbody>
          </Box>

        }
      </ContainerLg>
      <Dialog
        isOpen={dialogIsOpen}
        onDismiss={() => setDialogOpen(false)}
        aria-labelledby="header-id"
      >
        <Dialog.Header id="header-id">Kontrolli</Dialog.Header>
        <Box p={3}>
          <Text fontFamily="sans-serif">Saatsime nüüd testiks emaili, sõnumi või tegime kõne sinu poolt seadistatud aadressile.
            Mitte-test teavitused on samasugused, aga ilma test eesliiteta. Kui testteavitus ei jõudnud kohale siis kontrolli
          ega sisestatud andmetes ei ole vigu.</Text>
          <Box display="flex" mt={3} justifyContent="flex-end">
            <Button sx={{mr: 1}} variant='primary' onClick={ () => setDialogOpen(false)}>OK</Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  </Box>
}

function printWeekdaysFromIntBit(weekdays: WeekdayBooleans) {
  const ret: string[] = [];
  const dayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const estNames = ['E', 'T', 'K', 'N', 'R', 'L', 'P'];

  dayNames.forEach((dayName, index) => {
    // @ts-ignore
    if (weekdays[dayName] === true) {
      ret.push(estNames[index]);
    }
  });

  if (ret.length === 7) {
    return "E-P";
  }

  return ret.join(',');
}

export default Notifications;


function showMinutesPrecision(time: string | undefined ) {
  if (time === undefined || time === null || time === "") {
    return;
  }

  return time.substring(0,5);
}

function showDuration(from: string | undefined, to: string | undefined, tz: string) {
  const f = showMinutesPrecision(from);
  const t = showMinutesPrecision(to);
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzToDisplay = (currentTz === tz)? '' : ` ${tz}`;

  if (f === undefined && t === undefined) {
    return;
  }

  if (f === undefined) {
    return `Kuni ${t}${tzToDisplay}`;
  }

  if (t === undefined) {
    return `Alates ${f}${tzToDisplay}`;
  }

  return `${f} - ${t}${tzToDisplay}`;
}
