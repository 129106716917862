import { Box, FormControl, Button, TextInput, Flash } from '@primer/react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './images/Logo_icon.svg';
import { useForm } from 'react-hook-form';
import { nonInterceptedApi } from './service/api';

function Login() {

  return (
    <Box display="flex" sx={{ bg: 'canvas.default', flexFlow: 'column', minHeight: '100vh' }} justifyContent={'center'}>
      <Box display="flex" mb={2} justifyContent={'center'} width={1}>
        <img src={logo} width="56" height="56" alt="Protecthum" style={{ margin: '3px' }} />
      </Box>
      <Box display="flex" justifyContent={'center'}>
        <h4>Logi sisse Protecthumi</h4>
      </Box>
      <Box
        display="flex"
        flexWrap={['wrap', 'nowrap']}
        mt={3}
        pb={100}
        /* to push little up */ justifyContent={'center'}
        width={1}
      >
        <Box
          borderWidth="1px"
          borderStyle="solid"
          borderColor="border.default"
          borderRadius={5}
          p={3}
          width={340}
          sx={{ backgroundColor: 'canvas.subtle' }}
        >
          <Box display="grid" gridGap={3} px={2}>
            <LocalLoginForm/>
            <hr />
            <Button variant='primary' as={Link} onClick={() => window.location.href = '/api/auth/google'} sx={{ marginBottom: 3 }}>
              Google login
            </Button>
            <Button variant='primary' as={Link} onClick={() => window.location.href = '/api/auth/facebook'}>
              Facebook login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;


function LocalLoginForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{ username: string; password: string }>();

  async function onSubmit(form: { username: string; password: string }) {
    try {
      const login = await nonInterceptedApi.post('auth/local', form);
      navigate(login.data.redirect? login.data.redirect : '/loggedIn');
    } catch (err) {
      setError('username', {type: 'value', message:'Vale kasutajanimi või parool!'});
      setError('password', {type: 'value', message:'Vale kasutajanimi või parool!'});
    }
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
  <Box display="grid" gridGap={3} px={0}>
    { errors.password &&
    <Flash variant="danger">
      {errors.password.message}
    </Flash>
    }
    <FormControl>
      <FormControl.Label>E-mail</FormControl.Label>
      <TextInput block type='email' {...register('username')} />
    </FormControl>
    <FormControl sx={{ marginBottom: 3 }}>
      <FormControl.Label>Parool</FormControl.Label>
      <TextInput block type="password" {...register('password')}/>
      <FormControl.Caption><a href="/resetPassword">Unustasid parooli?</a> | <Link to='/createAccount'>Tee konto!</Link></FormControl.Caption>
    </FormControl>
    <Button variant='primary'>Logi sisse</Button>
  </Box>
  </form>
}
