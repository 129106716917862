import { Box, FormControl, TextInput, Button, Flash, StyledOcticon, Tooltip, Dialog, Text } from '@primer/react';
import React, { useEffect, useState } from 'react';
import { ContainerMd } from './components/layout/container';
import { SubheadHeading } from './components/subhead';
import Header from './Header';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import api from './service/api';
import { Auth, User } from './service/ProtecthumAPI';
import { CheckIcon } from '@primer/octicons-react';
import { getCurrentUser, logout } from './service/user.service';
import { FormFooter35 } from './components/FormFooter';
import { H3 } from './components/utilities/typography';

function useAuthData() {
  const [auths, setAuths] = useState<Auth[] | undefined>();

  function getAuths() {
    api.get('auth/user/auths').then( res => {
      setAuths(res.data);
    })
  }

  async function deleteAuth(id: number) {
    await api.delete<'auth/user/auths/:id'>('auth/user/auths/' + id);
    getAuths();
  }

  useEffect(() => {
    getAuths();
  }, []);

  return {auths, getAuths, deleteAuth}
}

function useUserData() {
  const [user, setUser] = useState<User | undefined>();

  function getUser() {
    api.get('auth/user').then( res => {
      setUser(res.data);
    })
  }

  async function updateUser(user: { name: string }) {
    await api.post<'auth/user'>('auth/user', user);
    getUser();
  }

  async function deleteUser() {
    await api.delete<'auth/user'>('auth/user');
  }

  useEffect(() => {
    getUser();
  }, []);

  return {user, getUser, updateUser, deleteUser}
}


function ProfilePage() {
  const { auths } = useAuthData();
  const { user, updateUser, deleteUser } = useUserData();
  const [searchParams] = useSearchParams();
  const mergedAuth = searchParams.get("mergedAuth");
  const mergedAuthParty = getCurrentUser()!.party.toUpperCase();

  const [deleteIsOpen, setOpenDelete] = useState(false);
  const returnFocusRef = React.useRef(null);

  async function deleteUserAndLogOut() {
    await deleteUser();
    await logout();
    localStorage.clear();
    window.location.assign('/login');
  }

  if (!user) {
    return <></>
  }

  return (
    <>
      <Header/>
      <Box as='main'>
        <ContainerMd sx={{ px: [16, 24, 32], my: 6 }}>
          <SubheadHeading>Sinu profiil</SubheadHeading>

          <Box display="grid" gridGap={3}>
            <H3 sx={{ pt: 3 }}>Kasutaja</H3>
            <UserForm preloadedValues={user} updateUser={updateUser}/>
            <Box>
              <Button variant='danger' ref={returnFocusRef} onClick={() => setOpenDelete(true)}>Kustuta kasutaja ja kõik mu andmed</Button>
            </Box>

            <Dialog
              returnFocusRef={returnFocusRef}
              isOpen={deleteIsOpen}
              onDismiss={() => setOpenDelete(false)}
              aria-labelledby="header-id"
            >
              <Dialog.Header id="header-id">Kasutaja kustutamine</Dialog.Header>
              <Box p={3}>
                <Text>Oled sa kindel, et tahad oma kasutaja ja kõik sellega seotud andmed siit süsteemist kustutada?</Text>
                <Box display="flex" mt={3} justifyContent="flex-end">
                  <Button sx={{mr: 1}} onClick={() => setOpenDelete(false)}>Ei</Button>
                  <Button variant="danger" onClick={() => deleteUserAndLogOut()}>Jah, Kustuta</Button>
                </Box>
              </Box>
            </Dialog>


            { auths !== undefined && <>
              <H3 sx={{ pt: 3 }}>Seotud autentimisteenused</H3>
              {
                mergedAuth != null &&
                <Flash variant="success">
                  <StyledOcticon icon={CheckIcon} />
                  Sinu Protecthumi kontole on lisatud {mergedAuthParty} autentimine! Sa oled selle abil nüüd sisse logitud.
                </Flash>
              }
              <Box borderBottomWidth={1} borderBottomStyle="solid" pb={3}>
                <OAuth party='google' name='Google'/>
              </Box>
              <Box borderBottomWidth={1} borderBottomStyle="solid" pb={3}>
                <OAuth party='facebook' name='Facebook'/>
              </Box>
              <Box>
                <LocalAuth/>
              </Box>

            </>
            }
          </Box>
        </ContainerMd>
      </Box>
    </>
  );
}

function LocalAuth() {
  const { auths, deleteAuth } = useAuthData();
  const currentParty = getCurrentUser()!.party;

  function hasLocalAuth() {
    return auths?.find(a => a.party === 'local') !== undefined;
  }

  function getAuth() {
    return auths?.find(a => a.party === 'local');
  }

  return hasLocalAuth()?
    <>Kohalik konto<br/>
      E-mail: { getAuth()?.partyId }<br/>
      {currentParty === 'local' ?
        <Tooltip aria-label="Käesolevat autentimist ei luba me lahti ühendada. Logi sisse teisel viisil, et see siin eemaldada.">
          <Button disabled onClick={() => deleteAuth(getAuth()!.id)} variant='danger' as={Link}>Seo kohalikust kontost lahti</Button>
        </Tooltip>
        :
        <Button onClick={() => deleteAuth(getAuth()!.id)} variant='danger' as={Link}>Kustuta kohalik kasutaja</Button>
      }
    </>
    : <>
      Kohalikku kontot veel pole.<br/>
      <Button variant='primary' as={Link} onClick={() => window.location.href = '/login'}>Logi sisse teise konto kasutaja ja parooliga</Button> </>
}

// connect/disconnect with Oauth provider
function OAuth(props: { party: 'google' | 'facebook', name: 'Google' | 'Facebook'}) {
  const { auths, deleteAuth } = useAuthData();
  const currentParty = getCurrentUser()!.party;

  function hasAuth(party: string) {
    return auths?.find(a => a.party === party) !== undefined;
  }

  function getAuth(party: string) {
    return auths?.find(a => a.party === party);
  }

  return <>
    { hasAuth(props.party) &&
      <>Seotud {props.name} kontoga.
        {currentParty === props.party ?
          <Tooltip aria-label="Käesolevat autentimist ei luba me lahti ühendada. Logi sisse teisel viisil, et see siin eemaldada.">
            <Button disabled onClick={() => deleteAuth(getAuth(props.party)!.id)} variant='danger' as={Link}>Seo {props.name} kontost lahti</Button>
          </Tooltip>
        :
          <Button onClick={() => deleteAuth(getAuth(props.party)!.id)} variant='danger' as={Link}>Seo {props.name} kontost lahti</Button>
        }
      </>
    }
    { !hasAuth(props.party) &&
      <Button variant='primary' as={Link} onClick={() => window.location.href = '/api/auth/' + props.party}>Seo {props.name} kontoga</Button>
    }
  </>
}


interface ProfileForm {
  name: string,
}

function UserForm(props: { preloadedValues: ProfileForm | undefined, updateUser: Function }) {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<ProfileForm>({ defaultValues: props.preloadedValues });

  function onSubmit(form: ProfileForm) {
    props.updateUser(form);
  }

  return (<form onSubmit={handleSubmit(onSubmit)}>
    <Box display="grid" gridGap={3}>
      <FormControl>
        <FormControl.Label>
          Nimi
        </FormControl.Label>
        <TextInput block {...register('name', { required: { value: true, message: 'Nimi peab olemas olema' } })} />
        {errors.name && <FormControl.Validation variant='error'>{ errors.name.message }</FormControl.Validation>}
      </FormControl>
      <FormFooter35>
        <Button variant='primary' type='submit'>
          Muuda
        </Button>
      </FormFooter35>

    </Box>
  </form>);
}

export default ProfilePage;
