import Header from './Header';
import { SubheadHeading } from './components/subhead';
import React, { useEffect, useState } from 'react';
import { ContainerLg } from './components/layout/container';
import { useNavigate, useParams } from 'react-router-dom';
import HeatMap from './components/HeatMap';
import api from './service/api';
import { Box, Button, FormControl, Link, Select, Text } from '@primer/react';
import { FormFooter35 } from './components/FormFooter';
import DeviceHeader from './DeviceHeader';
import { useDevice } from './Notifications';
import { useForm } from 'react-hook-form';
import { getLocalTime } from './Devices';

interface TeachForm {
  alarmId: string
  actual: string // -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6
}

const actuals = {
 '-1': 'Teadmata',
  '0': 'Alarmi pole',
  '1': 'Tuli',
  '2': 'Suits',
  '3': 'Liikumine',
  '4': 'Liikuv keha',
  '5': 'Seisev keha',
  '6': 'Kuum keha',
} as { [key: string]: string }

function AlarmDetails() {
  const { id, alarmId } = useParams();
  const [alarm, setAlarm] = useState(undefined as any | undefined);
  const [teaches, setTeacings] = useState(undefined as any | undefined);
  const navigate = useNavigate();
  const { device } = useDevice(id as string);
  const {
    register,
    handleSubmit,
  } = useForm<TeachForm>();

  function getTeaches() {
    api.get<'devices/:id/alarms/:alarmId/teach'>(`devices/${id}/alarms/${alarmId}/teach`).then((resp) => {
      setTeacings(resp.data);
    });
  }

  useEffect(() => {
    api.get<'devices/:id/alarms/:alarmId'>(`devices/${id}/alarms/${alarmId}`).then((resp) => {
      setAlarm(resp.data);
    });
    getTeaches();
  }, [id, alarmId]);

  async function onSubmit( form: TeachForm) {
    form.alarmId = alarmId as string;
    form.actual = parseInt(form.actual as string) as unknown as TeachForm['actual'];
    await api.post<'devices/:id/teach'>(`devices/${id}/teach`, form);
    getTeaches();
  }

  return (<>
    <Header />
      <Box as="main">
        {device && <DeviceHeader device={device} selected="alarms"/>}
      </Box>
    <ContainerLg sx={{ px: [16, 24, 32], my: 6 }}>
      <SubheadHeading>{'Alarm nr ' + alarmId }</SubheadHeading>
      <h3>Termopilt:</h3>
      <Box sx={{ py:2 }}>Aeg: {alarm && getLocalTime(alarm.startTime)}</Box>
      <table>
        <tbody>
          <tr>
            <td>
              {
                alarm && alarm.heatmap && <HeatMap data={alarm.heatmap} />
              }
            </td>
            <td style={{verticalAlign: 'top'}}>
              {
                alarm && <Box sx={{borderColor: 'border.default', borderWidth: 1,borderStyle: 'solid', ml: 2, borderRadius: '6px', p:2 }}>
                  <Box sx={{p: 1}}>Temperatuur: {alarm.temperature + '℃'}</Box>
                  <Box sx={{p: 1}}><div style={{backgroundColor: `rgb(0,0,255)`, height: 20, width: 20, display: 'inline-block'}}></div> Min:  {alarm.min + '℃'}</Box>
                  <Box sx={{p: 1}}><div style={{backgroundColor: `rgb(255,0,0)`, height: 20, width: 20, display: 'inline-block'}}></div>  Max: {alarm.max + '℃'}</Box>
                </Box>
              }
            </td>
          </tr>
        </tbody>
      </table>

      <Box sx={{py: 3}}><h3>Tagasiside</h3></Box>
      {
        teaches && getTeachesTable(teaches)
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormControl.Label>Tegelik alarm</FormControl.Label>
          <Select {...register('actual', { required: { value: true, message: 'Vali tegelik alarm' } })}>
            {
              Object.entries(actuals).map(([k,v]) => {
                return (<Select.Option key={k} value={ k }>{ v }</Select.Option>)
              })
            }
          </Select>
        </FormControl>
        <Button variant='primary' type='submit' sx={{my: 1}}>
          Saada seadmesse
        </Button>
      </form>

      <FormFooter35>
        <Button as={Link} onClick={() => navigate(`/devices/${id}/alarms`)} sx={{ margin: 1 }}>
          Tagasi
        </Button>
      </FormFooter35>
    </ContainerLg>
    </>
  )
}

export default AlarmDetails;

function getTeachesTable(teaches: any[]) {
  return (<Box as='table' sx={{ width: '100%',
    mb: '4',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '> thead tr td': {borderTop: '1px solid'},
    '> thead tr td, > tbody, tr, td': { borderBottom: '1px solid', padding: '5px', borderColor: 'border.default'},
    '> thead tr td:first-child, > tbody tr td:first-child': {borderLeft: '1px solid', borderColor: 'border.default'},
    '> thead tr td:last-child, > tbody tr td:last-child': {borderRight: '1px solid', borderColor: 'border.default'},
    '> thead tr first:child, > tbody tr first:child': {borderLeft: '1px solid', borderColor: 'border.default'},
    '> thead tr:first-child td:first-child': {borderTopLeftRadius: '6px'},
    '> thead tr:first-child td:last-child': {borderTopRightRadius: '6px'},
    '> tbody tr:last-child td:first-child': {borderBottomLeftRadius: '6px'},
    '> tbody tr:last-child td:last-child': {borderBottomRightRadius: '6px'}
  }}>
    <Box as='thead' sx={{backgroundColor: 'canvas.subtle'}}>
      <Box as='tr'>
        <td><Text fontWeight={'bold'}>Aeg</Text></td>
        <td><Text fontWeight={'bold'}>Sõnum</Text></td>
        <td><Text fontWeight={'bold'}>Vastus</Text></td>
      </Box>
    </Box>
    <tbody>
      {teaches.map((item, index) => {
        return (
            <Box as='tr' key={item.id} className="Box-row">
              <Box as='td' sx={{p:2}} >{ getLocalTime(item['insert_time']) }</Box>
              <Box as='td' sx={{p:2}} >{ actuals['' + item.request.Actual] }</Box>
              <Box as='td' sx={{p:2}} >{ item.response?.Answer ? item.response?.Answer : 'Vastust pole' }</Box>
            </Box>
          );
        })}
    </tbody>
  </Box>)
}