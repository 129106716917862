import api from './api';
import { User } from './ProtecthumAPI';

/*
Security is in stored HttpOnly cookie that is considered more secure than keeping token visible to JS.
When login is done then cookie is available and user endpoint return user data that is stored/cached in browser local
storage (setCurrentUser). By exp timestamp and 401 responses its known when session ends.
*/

interface UserInfo {
  exp: number
  int: number
  party: string
  partyId: string
  isAdmin?: boolean
}

export function setCurrentUser(user: any) {
  localStorage.setItem('currentUser', JSON.stringify(user));
}

export function getCurrentUser(): User & UserInfo | null {
  const userInfo = localStorage.getItem('currentUser');
  if (userInfo !== null) {
    return JSON.parse(userInfo);
  }
  return null;
}

export function hasValidToken() {
  return !isExpired();
}

export function isExpired() {
  const now = new Date().getTime() / 1000;
  const userInfo = getCurrentUser();
  if (!userInfo) {
    return true;
  }

  return userInfo.exp < now;
}

export function isAuthenticated() {
  return getCurrentUser() !== null;
}

export async function logout() {
  await api.post('auth/logout');
}
