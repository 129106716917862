import styled, { css } from 'styled-components';
import { themeGet } from '@primer/react';

/*
Componentization of https://github.com/primer/css/blob/v19.8.2/src/subhead/subhead.scss

https://primer.style/css/components/subhead
*/

const Subhead = styled.div<{ spacious?: boolean }>`
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${themeGet('colors.border.muted')};
  flex-flow: row wrap;
  justify-content: flex-end;
  ${({ spacious }) =>
  spacious &&
  css`
      margin-top: 40px;
    `};
`;

const SubheadHeading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  flex: 1 1 auto;
  order: 0;
`;

export { Subhead, SubheadHeading };
