import { Avatar, Header, ActionMenu, ActionList, StyledOcticon, Text } from '@primer/react';
import { getCurrentUser, hasValidToken, logout } from './service/user.service';
import logo from './images/Logo_icon.svg';
import { Link } from 'react-router-dom';
import { PersonFillIcon, PersonIcon, SignOutIcon, TriangleDownIcon } from '@primer/octicons-react';
import React from 'react';

function MyHeader() {
  if (!hasValidToken()) { // to decrease flickering
    return <></>
  }

  return (
    <Header>
      <Header.Item>
        <Header.Link href='/'>
            <img alt="Protecthum" src={logo} width="32" height="32" style={{ marginTop: -1, marginBottom: -1 }} />
        </Header.Link>
      </Header.Item>
      <Header.Item>
        <Header.Link as={Link} to='/devices'>Seadmed</Header.Link>
      </Header.Item>
      <Header.Item full /* because of this "full" */>
      </Header.Item>
      <UserMenu/>
    </Header>
  );
}


function UserMenu() {
  const userProfilePicture = getCurrentUser()!.avatarUrl;

  function doLogout() {
    logout().then(() => {
      localStorage.clear();
      window.location.assign('/login');
    });
  }

  return (
    <Header.Item sx={{ cursor: 'pointer' }}>

      <ActionMenu>
        <ActionMenu.Anchor>
            <span>
              { getCurrentUser()?.isAdmin && <Text style={{ color: 'red', fontWeight: 'bold'}}>Admin </Text> }
              {userProfilePicture &&
              <Avatar
                src={userProfilePicture}
                square
                size={32}
                aria-hidden={'true'}
                sx={{ marginTop: -1, marginBottom: -1 }}
              />
              }
              {!userProfilePicture &&
                <StyledOcticon icon={PersonFillIcon} size={32} sx={{ marginTop: -1, marginBottom: -1 }}/>
              }
              <TriangleDownIcon />
            </span>
        </ActionMenu.Anchor>
        <ActionMenu.Overlay>
          <ActionList>
            <ActionList.Item as={Link} to='/profile'>
              <ActionList.LeadingVisual>
                <PersonIcon />
              </ActionList.LeadingVisual>
              Sinu profiil
            </ActionList.Item>
            <ActionList.Item as='a' onSelect={doLogout}>
              <ActionList.LeadingVisual>
                <SignOutIcon />
              </ActionList.LeadingVisual>
              Logi välja
            </ActionList.Item>
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </Header.Item>
  );
}


export default MyHeader;
