import { Box } from '@primer/react';

export function FormFooter(props: { children: any }) {
  return (
    <Box mt={3} pb={3}>
      <Box display="flex" justifyContent={"flex-end"} mt={1} flexWrap={["wrap", "nowrap"]} alignItems={"center"}>
        {props.children}
      </Box>
    </Box>
  );
}

// https://github.com/primer/css/blob/v19.8.2/src/forms/form-control.scss#L232=
// by CSS project .form-actions class https://primer.style/css/components/forms#form-actions
// arranges buttons margins, no need to specify those in buttons
export function FormFooter35(props: { children: any }) {
  return (
    <Box sx={{
      '> *': { float: 'right' },
      '> * + *': { marginRight: '5px' }
    }}>
      {props.children}
    </Box>
  );
}
