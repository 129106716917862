import Header from './Header';
import DevicesForm from './DevicesForm';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DeviceStored } from './service/ProtecthumAPI';
import api from './service/api';
import { ContainerMd } from './components/layout/container';
import { SubheadHeading } from './components/subhead';

function DevicePage() {
  const { id } = useParams();
  const [device, setDevice] = useState<DeviceStored | undefined>();

  useEffect(() => {
    if (id) {
      api.get<'devices/:id'>('devices/' + id).then( res => {
        setDevice(res.data);
      })
    }
  }, [id]);

  return (
    <>
      <Header />
      <ContainerMd sx={{pt: 4}}>
        <SubheadHeading>Seade</SubheadHeading>
        {!id || device? <DevicesForm preloadedValues={device}/> : <></>}
      </ContainerMd>
    </>
  );
}

export default DevicePage;
