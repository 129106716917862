import { Route } from 'react-router';
import { Routes, Navigate, Path } from 'react-router-dom';
import Login from './Login';
import Devices from './Devices';
import { isAuthenticated, isExpired } from './service/user.service';
import LoggedIn from './LoggedIn';
import DevicePage from './DevicePage';
import Alarms from './Alarms';
import ShareInbound from './ShareInbound';
import CreateAccount from './CreateAccount';
import Profile from './Profile';
import CreateAccountFinish from './CreateAccountFinish';
import ResetPassword from './ResetPassword';
import Notifications from './Notifications';
import NotificationAdd from './NotificationAdd';
import AlarmDetails from './AlarmDetails';
import Settings from './Settings';
import Sharing from './Sharing';
import NotificationEdit from './NotificationEdit';

function storeCurrentUrl(currentUrl: Path) {
  localStorage.setItem('preloginUrl', JSON.stringify(currentUrl));
}
export function getStoredUrl() {
  const item = localStorage.getItem('preloginUrl');
  if (item !== null && item !== undefined) {
    return JSON.parse(item);
  }
  return null;
}
export function hasStoredUrl() {
  const storedUrl = localStorage.getItem('preloginUrl');
  return (storedUrl !== undefined && storedUrl !== null);
}

export function removeStoredUrl() {
  localStorage.removeItem('preloginUrl');
}

// returns react router v6 navigatable URL: path + search + hash - means Path object that looks to be subset of window.location
function getCurrentUrl() {
  return window.location;
}

function PrivateRoute(props: { children: any }) {
  if (isAuthenticated() && !isExpired()) {
    return props.children;
  }

  storeCurrentUrl(getCurrentUrl());
  return <Navigate to="/login" />;
}

function Router() {
  return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/createAccount" element={<CreateAccount />} />
        <Route path="/createAccount/:token" element={<CreateAccountFinish />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/loggedIn" element={<LoggedIn />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Devices />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices"
          element={
            <PrivateRoute>
              <Devices />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/add"
          element={
            <PrivateRoute>
              <DevicePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id/alarms"
          element={
            <PrivateRoute>
              <Alarms />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id/alarms/:alarmId"
          element={
            <PrivateRoute>
              <AlarmDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id/notifications"
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id/notificationsAdd"
          element={
            <PrivateRoute>
              <NotificationAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id/notifications/:notificationId"
          element={
            <PrivateRoute>
              <NotificationEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/shares/:hash"
          element={
            <PrivateRoute>
              <ShareInbound />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices/:id/sharing"
          element={
            <PrivateRoute>
              <Sharing />
            </PrivateRoute>
          }
        />
      </Routes>
  );
}

export default Router;
