import restTypedAxios from 'restyped-axios';
import { ProtecthumAPI } from './ProtecthumAPI';

const api = restTypedAxios.create<ProtecthumAPI>({
  baseURL: '/api/',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
});

export const nonInterceptedApi = restTypedAxios.create<ProtecthumAPI>({
  baseURL: '/api/',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});


export default api;
